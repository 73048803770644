"use client";
import Image from "next/image";
import embarcaLogo from "@/app/assets/embarca-ai-logo.svg";
import FormSearchCityDesktop from "./FormSearchCityDesktop";
import FormSearchCityMobile from "./FormSearchCityMobile";
import Link from "next/link";

interface Props {
  props?: {
    title: string;
    subtitle: string;
    bg_url: string;
    link: string;
  };
}

export default function SearchTicketsForm({ props }: Props) {
  //title e subtitle não estão sendo usados, pois atualmente está estático
  const linkGooglePlay = "https://play.google.com/store/apps/details?id=com.arca.maaspassenger.alfa&hl=pt_BR";
  const linkAppStore = "https://apps.apple.com/br/app/embarca-ai/id1508467440";

  const { title, subtitle, bg_url, link } = props || {
    title: "Título",
    subtitle: "Subtítulo",
    bg_url: "/banner-background.webp"
  };

  return (
    <>
      {/* Form for mobile */}
      <section className="lg:hidden block bg-gray-5 rounded-b-3xl">
        <div className="flex justify-center">
          <Image
            className="mt-11"
            src={embarcaLogo}
            alt="Logo da Embarca.ai"
            priority
          />
        </div>
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
          <div className="flex flex-col text-center items-center gap-2">
            <p className="text-white text-xl">
              {/* {title} */}
              Garanta <strong>30% </strong>
              na sua primeira compra no app!<br />
              Usando o cupom <strong>PRIMEIRA30APP</strong>
            </p>
            <p className="text-primary">Baixe nosso app</p>
            <Link className="flex px-2 items-center bg-white border border-black rounded-xl text-gray-900 font-bold" href={linkAppStore}>
              <Image src="/app-store.svg" width={40} height={40} alt="App store logo" />
              App Store
            </Link >
            <Link className="flex px-2 items-center bg-white border border-black rounded-xl text-gray-900 font-bold" href={linkGooglePlay}>
              <Image src="/google-play.svg" width={40} height={40} alt="Google Play logo" />
              Google Play
            </Link>
          </div>
          <FormSearchCityMobile />
        </div>
      </section >
      {/* Form for desktop  */}
      <section
        style={{ "--image-url": `url(${bg_url})` } as React.CSSProperties}
        className={
          "relative hidden lg:flex bg-[image:var(--image-url)] bg-no-repeat bg-center bg-cover h-[33.75rem] mb-20"
        }
      >
        <div className="relative">
          <div className="absolute w-max top-1/4 left-[calc(50vw-36rem)] max-w-6xl bg-black rounded-[1.875rem] p-8 bg-opacity-40">
            <h1 className="text-white text-[2.5rem]">
              {/* {title} */}
              Passagens de ônibus <br />
              online Embarca.ai
            </h1>
            <p className="text-white mt-4 font-thin">
              {/* {subtitle} */}
              Viaje de <strong>ônibus</strong> com <strong>facilidade</strong> e{" "}
              <strong>economia</strong>
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1 absolute bottom-10 right-1/2 translate-x-1/2 rounded-xl text-gray-900 p-5 w-fit mx-auto">
          <Link className="flex px-2 items-center bg-white border border-black rounded-xl text-gray-900 font-bold text-sm" href={linkAppStore}>
            <Image src="/app-store.svg" width={40} height={40} alt="App store logo" />
            App Store
          </Link >
          <Link className="flex px-2 items-center  bg-white border border-black rounded-xl text-gray-900 font-bold text-sm" href={linkGooglePlay}>
            <Image src="/google-play.svg" width={40} height={40} alt="Google Play logo" />
            Google Play
          </Link>
        </div>

        <FormSearchCityDesktop heightOption="standardhigh" />
      </section>
    </>
  );
}
